import React, {useState, useEffect} from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, set, get } from "firebase/database";
import { useNavigate, useParams } from 'react-router-dom';

function UpdateWrite() {

    const navigate = useNavigate();
    const {firebaseId} = useParams(); // Get the firebaseId from the URL parameters

    // State management for input fields
    let [inputValue1, setInputValue1] = useState("");
    let [inputValue2, setInputValue2] = useState("");

    // Fetch data when the component mounts or firebaseId changes
    useEffect(() => {
        const fetchData = async () => {
            const db = getDatabase(app);
            const dbRef = ref(db, "nature/fruits/" + firebaseId);
            const snapshot = await get(dbRef); // Fetch data from the reference
            if (snapshot.exists()) {
                const targetObject = snapshot.val();
                setInputValue1(targetObject.fruitName); // Set the state with fetched data
                setInputValue2(targetObject.fruitDefinition);
            } else {
                alert("error"); // Handle the case where no data exists
            }
        }
        fetchData();
    }, [firebaseId]); // Dependency array includes firebaseId to re-fetch if it changes

    // Function to overwrite data in Firebase
    const overwriteData = async () => {
        const db = getDatabase(app);
        const newDocRef = ref(db, "nature/fruits/" + firebaseId); // Reference to the specific fruit entry
        set(newDocRef, {
            fruitName: inputValue1,
            fruitDefinition: inputValue2
        }).then(() => {
            alert("data updated successfully"); // Alert on successful update
        }).catch((error) => {
            alert("error: ", error.message); // Error handling
        });
    }

    return (
        <div>
            <h1>UPDATE</h1>

            {/* Input fields for user data */}
            <input type='text' value={inputValue1}
                   onChange={(e) => setInputValue1(e.target.value)}/>

            <input type='text' value={inputValue2}
                   onChange={(e) => setInputValue2(e.target.value)}/> <br/>

            {/* Button to update data */}
            <button onClick={overwriteData}>UPDATE</button>
            <br />
            <br />
            <br />

            {/* Navigation buttons */}
            <button className='button1' onClick={() => navigate("/updateread")}>GO UPDATE READ</button> <br />
            <button className='button1' onClick={() => navigate("/read")}>GO READ PAGE</button>
        </div>
    )
}

export default UpdateWrite;
