import React, {useState} from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';

function Read() {
    const navigate = useNavigate();

    // State management for storing fetched data
    let [fruitArray, setFruitArray] = useState([]);

    // Function to fetch data from Firebase
    const fetchData = async () => {
        const db = getDatabase(app);
        const dbRef = ref(db, "nature/fruits");
        const snapshot = await get(dbRef); // Fetch data from the reference
        if (snapshot.exists()) {
            setFruitArray(Object.values(snapshot.val())); // Update state with fetched data
        } else {
            alert("error"); // Handle the case where no data exists
        }
    }

    return (
        <div>
            <h1>READ</h1>
            {/* Button to trigger data fetching */}
            <button onClick={fetchData}> Display Data </button>
            <ul>
                {/* Display the fetched data */}
                {fruitArray.map((item, index) => (
                    <li key={index}>
                        {item.fruitName}: {item.fruitDefinition}
                    </li>
                ))}
            </ul>
            {/* Navigation buttons */}
            <button className='button1' onClick={() => navigate("/updateread")}>GO UPDATE READ</button> <br />
            <button className='button1' onClick={() => navigate("/")}>GO HOMEPAGE</button>
        </div>
    )
}

export default Read
