import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Write from "./components/Write";
import Read from "./components/Read";
import UpdateRead from "./components/UpdateRead";
import UpdateWrite from "./components/UpdateWrite";

function App() {
    return (
        <div className="App">
            {/* Setting up the Router for navigation */}
            <Router>
                {/* Defining the Routes for different components */}
                <Routes>
                    {/* Route to render the Write component at the root path */}
                    <Route path="/" element={<Write />} />
                    {/* Route to render the Write component at the /write path */}
                    <Route path="/write" element={<Write />} />
                    {/* Route to render the Read component at the /read path */}
                    <Route path="/read" element={<Read />} />
                    {/* Route to render the UpdateRead component at the /updateread path */}
                    <Route path="/updateread" element={<UpdateRead />} />
                    {/* Route to render the UpdateWrite component at the /updatewrite/:firebaseId path */}
                    <Route path="/updatewrite/:firebaseId" element={<UpdateWrite />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
