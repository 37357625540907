// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,             // API key from environment variables
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,     // Auth domain from environment variables
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,   // Database URL from environment variables
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,       // Project ID from environment variables
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // Storage bucket from environment variables
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, // Messaging sender ID from environment variables
    appId: process.env.REACT_APP_FIREBASE_APP_ID,               // Firebase App ID from environment variables
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID // Measurement ID from environment variables (optional)
};

// Initialize Firebase
const app = initializeApp(firebaseConfig); // Initialize Firebase app with the provided configuration
const analytics = getAnalytics(app); // Initialize Firebase Analytics for the app

export default app; // Export the initialized Firebase app for use in other parts of the application
