import React, {useState} from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, get, remove } from "firebase/database";
import { useNavigate } from 'react-router-dom';

function UpdateRead() {

    const navigate = useNavigate();

    // State management for storing fetched data
    let [fruitArray, setFruitArray] = useState([]);

    // Function to fetch data from Firebase
    const fetchData = async () => {
        const db = getDatabase(app);
        const dbRef = ref(db, "nature/fruits");
        const snapshot = await get(dbRef); // Fetch data from the reference
        if (snapshot.exists()) {
            const myData = snapshot.val();
            // Convert the fetched data into an array with IDs included
            const temporaryArray = Object.keys(myData).map(myFireId => {
                return {
                    ...myData[myFireId],
                    fruitId: myFireId
                }
            });
            setFruitArray(temporaryArray); // Update state with fetched data
        } else {
            alert("error"); // Handle the case where no data exists
        }
    }

    // Function to delete a fruit entry from Firebase
    const deleteFruit = async (fruitIdParam) => {
        const db = getDatabase(app);
        const dbRef = ref(db, "nature/fruits/" + fruitIdParam); // Reference to the specific fruit entry
        await remove(dbRef); // Remove the fruit entry
        window.location.reload(); // Reload the page to update the list
    }

    return (
        <div>
            <h1>UPDATE READ</h1>
            {/* Button to trigger data fetching */}
            <button onClick={fetchData}> Display Data </button>
            <ul>
                {/* Display the fetched data */}
                {fruitArray.map((item, index) => (
                    <>
                        <li key={index}>
                            {item.fruitName}: {item.fruitDefinition} : {item.fruitId}
                            {/* Button to navigate to the update page for the specific fruit */}
                            <button className='button1' onClick={() => navigate(`/updatewrite/${item.fruitId}`)}>UPDATE</button>
                            {/* Button to delete the specific fruit */}
                            <button className='button1' onClick={() => deleteFruit(item.fruitId)}>DELETE</button>
                        </li>
                    </>
                ))}
            </ul>
            {/* Navigation buttons */}
            <button className='button1' onClick={() => navigate("/")}>GO HOMEPAGE</button> <br />
            <button className='button1' onClick={() => navigate("/read")}>GO READ PAGE</button>
        </div>
    )
}

export default UpdateRead
