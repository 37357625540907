import React, {useState} from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, set, push } from "firebase/database";
import { useNavigate } from 'react-router-dom';

function Write() {

    const navigate = useNavigate();

    // State management for input fields
    let [inputValue1, setInputValue1] = useState("");
    let [inputValue2, setInputValue2] = useState("");

    // Function to save data to Firebase
    const saveData = async () => {
        const db = getDatabase(app);
        const newDocRef = push(ref(db, "nature/fruits")); // Create a new reference
        set(newDocRef, {
            fruitName: inputValue1,
            fruitDefinition: inputValue2
        }).then(() => {
            alert("data saved successfully");
        }).catch((error) => {
            alert("error: ", error.message); // Error handling
        });
    }

    return (
        <div>
            <h1>WRITE/HOMEPAGE</h1>

            {/* Input fields for user data */}
            <input type='text' value={inputValue1}
                   onChange={(e) => setInputValue1(e.target.value)}/>

            <input type='text' value={inputValue2}
                   onChange={(e) => setInputValue2(e.target.value)}/> <br/>

            {/* Button to save data */}
            <button onClick={saveData}>SAVE DATA</button>
            <br />
            <br />
            <br />

            {/* Navigation buttons */}
            <button className='button1' onClick={() => navigate("/updateread")}>GO UPDATE READ</button> <br />
            <button className='button1' onClick={() => navigate("/read")}>GO READ PAGE</button>
        </div>
    )
}

export default Write;
